<template>
  <div>
    <v-row>
      <v-col
        md="7"
        class="d-flex justify-center align-center"
        v-if="this.$vuetify.breakpoint.smAndUp"
        style="background: #f4f5fa; height: 100vh"
      >
        <lottie :width="600" :options="defaultOptions" />
      </v-col>
      <v-col
        style="background: white"
        cols="12"
        md="5"
        class="d-flex justify-center align-center"
      >
        <v-card class="mx-auto v-card--flat cardLogin" style="max-width: 400px">
          <v-card-text class="mb-0 pb-0">
            <h5 class="text-h5 font-weight-semibold mb-1">Bienvenidos 👋🏻</h5>
            <p class="mb-0">Por favor para continuar inicie sesion</p>
          </v-card-text>
          <v-img
            class="white--text align-end logo"
            height="155px"
            src="../assets/1.png"
            style="object-fit: cover"
          >
          </v-img>
          <v-card-text class="pt-0">
            <v-form ref="form" action v-model="valid" lazy-validation>
              <v-card-text class="pb-0">
                <v-text-field
                  rounded
                  outlined
                  v-model="email"
                  :rules="nameRules"
                  label="Usuario"
                  prepend-inner-icon="mdi-account"
                  required
                ></v-text-field>

                <v-text-field
                  rounded
                  outlined
                  v-model="password"
                  :type="verContraseña ? 'text' : 'password'"
                  :rules="ReglasContraseña"
                  label="Contraseña"
                  prepend-inner-icon="mdi-key"
                  :append-icon="verContraseña ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="verContraseña = !verContraseña"
                  required
                ></v-text-field>
              </v-card-text>

              <v-card-actions class="justify-center">
                <v-btn
                  rounded
                  :loading="loading"
                  block
                  dark
                  color="#ff6001"
                  @click="submit"
                >
                  <v-icon left> mdi-email-arrow-right </v-icon>
                  ingresar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>

        <v-snackbar
          v-model="dialogErr"
          :timeout="timeout"
          rounded="pill"
          absolute
          bottom
          color="red accent-2"
        >
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="dialogErr = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import * as animationData from "../assets/lottie/carrito.json";

export default {
  data() {
    return {
      defaultOptions: {
        animationData: animationData.default,
      },
      text: "error en login",
      timeout: 3000,
      dialogErr: false,
      loading: false,
      valid: false,
      verContraseña: false,
      password: "",
      email: "",
      nameRules: [
        (v) => !!v || "Ingresar Usuario",
        //(v) => v.length <= 10 || "El usuario debe contener minimo 10",
        // (v) =>  /(\d)/.test(v) || "debe tener un numero"
      ],
      ReglasContraseña: [
        (v) => !!v || "Ingresar contraseña",
        // (v) => v.length <= 4 || "La contraseña debe contener minimo 10",
        // (v) => /([A-Z])/.test(v)  || "Debe contener una mayuscula",
      ],
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        console.log("validado");
        // let n=this.dni
        this.loading = true;

        try {
          await axios
            .post(process.env.VUE_APP_BASE_URL + "auth/signin/", {
              email: `${this.email}`,
              password: `${this.password}`,
            })
            .then((res) => {
              // this.respuesta = res.data[0].estado;
              console.log("login>>>>>>", res);
              let respuesta = res.data.usuario;

              // recordar poner condicion distinto de undefined
              if (res.data.usuario != undefined || res.data.usuario != null) {
                console.log("respuesta>>>>>", respuesta);
                this.email = "";
                this.password = "";
                this.token = JSON.stringify(res.data.usuario);
                localStorage.setItem("token", this.token);
                this.$store.dispatch("login", res.data.usuario);
              } else {
                this.dialogErr = true;
                this.dni = "";
                this.credencial = "";
              }
            });
        } catch (e) {
          console.log(e);
          this.dialogErr = true;
          this.email = "";
          this.password = "";
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
}
@media (max-width: 600px) {
  .logo {
    width: 320px;
    height: 95px !important;
  }
  .cardLogin {
    margin-top: 30px;
  }
}
</style>
