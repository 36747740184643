import axios from "axios";

const state = () => ({
  carrito: JSON.parse(localStorage.getItem('carrito')) || [],
  activarModuloPago: false,
  mediosPagos: [],
  productosPOS:[]
});
const getters = {
  carritoState(state) {
    return state.carrito;
  },
  productosPOSState(state) {
    return state.productosPOS;
  },
  mediosPagosState(state) {
    return state.mediosPagos;
  },
  ModuloPagoState(state) {
    return state.activarModuloPago;
  },
  totalCarrito(state) {
    // Calcula el total del carrito sumando el precio por unidades de cada producto
    return state.carrito.reduce((total, item) => {
      return total + item.precio_unitario * item.unidades;
    }, 0);
  },
  totalPagadoState(state) {
    // Calcula el total de todos los pagos realizados
    return state.mediosPagos.reduce((total, item) => {
      return total + parseFloat(item.pago);
    }, 0);
  },
  saldoRestanteState(state, getters) {
    // Calcula lo que falta por pagar
    const totalCarrito = getters.totalCarrito;
    const totalPagado = getters.totalPagadoState;
    let final =(totalCarrito - totalPagado).toFixed(2)
    
    return Number(final);
  }
};
const mutations = {
  cargarMasivaCarrito(state, payload) {
    // Asegúrate de que el payload contenga una lista válida de productos
    if (Array.isArray(payload) && payload.length) {
      // Remplaza el contenido del carrito con los nuevos productos del payload
      state.carrito = payload.map(producto => {
        // Asegurarse de que las unidades sean numéricas
        return {
          ...producto,
          unidades: Number(producto.unidades)
        };
      });

      // Actualizar el localStorage con el nuevo estado del carrito
      localStorage.setItem('carrito', JSON.stringify(state.carrito));

      // Verificar si el total del carrito alcanza el límite
      const total = getters.totalCarrito(state);
      if (total >= 170000) {
        state.activarModuloPago = true;
        console.log("El carrito ha alcanzado el límite de 170.000 pesos.");
      }
    } else {
      console.warn("El payload para cargar el carrito está vacío o no es válido.");
    }
  
},
  activarPago(state, payload) {
    state.activarModuloPago = payload;
    console.log("desde la mutacion", state.activarModuloPago);
  },
  deleteIdCarrito(state, payload) {
    // Buscar el índice del producto en el carrito
    console.log("carrito", state.carrito);
    console.log("payload");

    const index = state.carrito.findIndex(
      (item) => item.id_producto === payload.id
    );
    console.log(index);

    if (index !== -1) {
      // Eliminar el producto del carrito
      state.carrito.splice(index, 1);
    }

    // Mostrar en consola el estado del carrito
    localStorage.setItem('carrito', JSON.stringify(state.carrito));
    console.log(
      "Producto eliminado. Estado actual del carrito:",
      state.carrito
    );
  },
  addCarrito(state, payload) {
    console.log('el payload',payload);
    // Asegurarte de que el campo recibido tenga unidades numéricas
    let campo = {
      ...payload,
      unidades: Number(payload.unidades),
    };
console.log('campo',campo);
    // Buscar el índice del producto existente en el carrito
    const existingItemIndex = state.carrito.findIndex(
      (item) => item.id_producto === campo.id_producto
    );
    


    if (existingItemIndex !== -1 ) {
      console.log("entra el add", state.carrito[existingItemIndex].unidades);
      console.log('el nuevo valor',campo.unidades);
      // Sumar las unidades al producto existente
      let unidadesExistente = Number(state.carrito[existingItemIndex].unidades);
      state.carrito[existingItemIndex].unidades = Number(campo.unidades) + unidadesExistente;
    } else {
      // Añadir un nuevo producto al carrito
      state.carrito.unshift(campo);
    }
// Verificar el total después de añadir el producto
const total = getters.totalCarrito(state);
if (total >= 170000) {
  state.activarModuloPago = true;
  console.log("El carrito ha alcanzado el límite de 170.000 pesos.");
}
    // Mostrar en consola el estado del carrito
    localStorage.setItem('carrito', JSON.stringify(state.carrito));
    console.log("el producto desde el store mutacion", state.carrito);
  },
  addPago(state, payload) {
    state.mediosPagos.push(payload);
    console.log(state.mediosPagos);
  },
  finalizarVenta(state,payload) {
    (state.carrito = []),
      (state.activarModuloPago = false),
      (state.mediosPagos = []);
      localStorage.removeItem('carrito');
      localStorage.removeItem('datosFactura');
      // Guarda o actualiza el valor de idUltimoPedido en localStorage
  localStorage.setItem('idUltimoPedido', payload);
  },
  eliminarMediosPagosState(state) {
    state.mediosPagos = [];
  },
  getProductosLoadPOS(state, payload) {
    state.productosPOS = payload;
    // state.loderProducts = false;
  }

};
const actions = {
  handleCarrito({ commit, getters }, producto) {
    return new Promise((resolve, reject) => {
      const total = getters.totalCarrito;
      if (producto.eliminar) {
        commit("deleteIdCarrito", producto);
        resolve();
      } else {
        const productoTotal = producto.precio_unitario * producto.unidades;
        if (total + productoTotal > 170000) {
          // Si el límite se supera, rechaza la promesa
          reject("No se puede agregar este producto. El carrito superaría el límite de 170.000 pesos.");
        } else {
          commit("addCarrito", producto);
          resolve();
        }
      }
    })},
     handleCarritoID({ commit }, payload){
      return new Promise(async(resolve, reject) => {
       try{
        let r =  await axios.get(
          process.env.VUE_APP_BASE_URL + "pedidos-pos/" + payload
        );
        console.log(r.data.response);
        commit("cargarMasivaCarrito",r.data.response.detalle_pedidos)
        resolve()
       }catch(e){
        console.log(e);
        reject("No se encontro la compra")
       }
       
      })},
  handlePago({ commit }, payload) {
    commit("activarPago", payload);
  },
  eliminarMediosPagos({ commit }) {
    commit("eliminarMediosPagosState");
  },
  handleMedioPago({ commit }, payload) {
    commit("addPago", payload);
  },
  handleFinalizarVenta({ commit }, payload) {
    commit("finalizarVenta", payload);
  },
  getProductosPOS({ commit, state }, refresh) {
    return new Promise(async (resolve) => {
      try {
       
        if (state.productosPOS.length !== 0 && !refresh) {
          console.log("entro en el return de productos");
          resolve();
          return;
        }
        state.productosPOS = [];
        // state.loderProducts = true;
        console.log('>>>>>',state.productosPOS);
        let resp = await axios.get(process.env.VUE_APP_BASE_URL + "products-pos");
        console.log("entro en el get productos pos",resp);
        commit("getProductosLoadPOS", resp.data);
        resolve();
      } catch (e) {
        console.log("erroorrrr",e);
      }
    });
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
